class navbarAnimation {
  handleNavbarAnimation() {
    const header = document.querySelector(".header");
    const navbar = document.querySelector(".navbar");
    const navbarSectionButtons = document.querySelectorAll(
      ".navbar__btn--section"
    );
    const dateContainer = document.querySelector(".header__date-container");

    const yDimDateContainer = dateContainer.getBoundingClientRect().y;
    const headerHeight = header.offsetHeight;

    document.addEventListener("scroll", function (e) {
      if (window.scrollY > headerHeight - 20) {
        navbarSectionButtons.forEach((navbarSectionButton) => {
          navbarSectionButton.classList.add("navbar__btn--scrolled-dark");
          navbar.classList.add("navbar__scrolled-dark");
        });
      } else {
        navbarSectionButtons.forEach((navbarSectionButton) => {
          navbarSectionButton.classList.remove("navbar__btn--scrolled-dark");
          navbar.classList.remove("navbar__scrolled-dark");
        });
      }

      if (window.scrollY > yDimDateContainer - 20) {
        dateContainer.classList.add("header__date-container--hidden");
      } else {
        dateContainer.classList.remove("header__date-container--hidden");
      }
    });
  }
}

export default new navbarAnimation();
