import fullpage from "fullpage.js";

class fullpageView {
  handleFullpageView() {
    const viewportWidth = window.innerWidth;

    console.log(viewportWidth);

    const fp = new fullpage("#fullpage", {
      sectionSelector: ".section",
      licenseKey: "gplv3-license",
      controlArrows: true,
      scrollBar: true,
      bigSectionsDestination: top,
      responsiveWidth: 600,
      fitToSection: false,
      verticalCentered: false,
      lazyLoading: false,
      navigation: true,
      navigationPosition: "right",
      credits: { enabled: false, label: "", position: "right" },
    });
  }
}

export default new fullpageView();
