// import productBG from "./../../img/Produkte/bg-produkte.jpg";
// import aboutMeBG from "./../../img/About-me/about-me_bg.jpg";
// import offerBG from "./../../img/Angebot/bg-angebot.jpg";

import landscape_1 from "./../../img/landscape-1.jpg";
import landscape_2 from "./../../img/landscape-2.jpg";
import landscape_3 from "./../../img/landscape-3.jpg";
import landscape_4 from "./../../img/landscape-4.jpg";
import landscape_5 from "./../../img/landscape-5.jpg";

import portrait_1 from "./../../img/portrait-1.jpg";
import portrait_2 from "./../../img/portrait-2.jpg";
import portrait_3 from "./../../img/portrait-3.jpg";
import portrait_4 from "./../../img/portrait-4.jpg";
import portrait_5 from "./../../img/portrait-5.jpg";

class imgView {
  lazyLoadingImg() {
    const imgTargets = document.querySelectorAll("img[data-src]");

    // LAZY LOADING OF IMAGES BELOW INITIAL VIEW
    const loadImg = function (entries, observer) {
      entries.forEach((entry) => {
        // safe guard
        if (!entry.isIntersecting) return;

        if (entry.target.dataset.src.includes("landscape-1")) {
          entry.target.src = landscape_1;
        } 
        if (entry.target.dataset.src.includes("landscape-2")) {
          entry.target.src = landscape_2;
        }
        if (entry.target.dataset.src.includes("landscape-3")) {
          entry.target.src = landscape_3;
        }
        if (entry.target.dataset.src.includes("landscape-4")) {
          entry.target.src = landscape_4;
        }
        if (entry.target.dataset.src.includes("landscape-5")) {
          entry.target.src = landscape_5;
        }

        if (entry.target.dataset.src.includes("portrait-1")) {
          entry.target.src = portrait_1;
        } 
        if (entry.target.dataset.src.includes("portrait-2")) {
          entry.target.src = portrait_2;
        }
        if (entry.target.dataset.src.includes("portrait-3")) {
          entry.target.src = portrait_3;
        }
        if (entry.target.dataset.src.includes("portrait-4")) {
          entry.target.src = portrait_4;
        }
        if (entry.target.dataset.src.includes("portrait-5")) {
          entry.target.src = portrait_5;
        }
        
        else {
          console.log("NO IMG SOURCE MATCH");
        }

        // remove blur only when loading of image is completed
        entry.target.addEventListener("load", function () {
          entry.target.classList.remove("lazy-img");
        });

        observer.unobserve(entry.target);
      });
    };

    const imgObserver = new IntersectionObserver(loadImg, {
      root: null,
      threshold: 0,
      rootMargin: "200px",
    });

    imgTargets.forEach((img) => imgObserver.observe(img));
  }
}

export default new imgView();
