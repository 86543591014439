import View from "./view";

class navbarView extends View {
  _parentElement = document.querySelector(".navbar");

  handleDropdown() {
    const navBtns = document.querySelectorAll(".navbar__btn--section--mobile");
    const navCheckbox = document.querySelector(".navbar__checkbox");
    const navCheckboxIcon = document.querySelector(".navbar__icon");

    // close dropdown when dropdown button was clicked
    navBtns.forEach((btn) => {
      btn.addEventListener("click", function (e) {
        e.preventDefault();
        navCheckbox.checked = false;
      });
    });
  }

  _generateMarkup(home) {
    return `
      <div class="navbar__section-buttons">
        <a
          href="#home"
          class="btn__simple navbar__btn--section smooth-scrolling"
          >Home</a
        >

        <a
          href="#our-story"
          class="btn__simple navbar__btn--section smooth-scrolling"
          >Unsere Geschichte</a
        >

        <a
          href="#ablauf"
          class="btn__simple navbar__btn--section smooth-scrolling"
          >Ablauf</a
        >

        <a
        href="#locations"
        class="btn__simple navbar__btn--section smooth-scrolling"
        >Locations</a
        >
      
        <a
          href="#hotel"
          class="btn__simple navbar__btn--section smooth-scrolling"
          >Hotel</a
        >

        <a
        href="#kontakt"
        class="btn__simple navbar__btn--section smooth-scrolling"
        >Kontakt & Infos</a
        >
      </div>


      <div class="navbar__nav-dropdown">
        <input type="checkbox" class="navbar__checkbox" id="navi-toggle" />

        <label for="navi-toggle" class="navbar__button--open">
          <span class="navbar__icon">&nbsp;</span>
        </label>

        <div class="navbar__nav-dropdown--content">
        <a
        href="#home"
        class="btn__simple navbar__btn--section smooth-scrolling navbar__btn--section--mobile"
        >Home</a
      >

      <a
        href="#our-story"
        class="btn__simple navbar__btn--section smooth-scrolling navbar__btn--section--mobile"
        >Unsere Geschichte</a
      >

      <a
        href="#ablauf"
        class="btn__simple navbar__btn--section smooth-scrolling navbar__btn--section--mobile"
        >Ablauf</a
      >

      <a
      href="#locations"
      class="btn__simple navbar__btn--section smooth-scrolling navbar__btn--section--mobile"
      >Locations</a
      >
    
      <a
        href="#hotel"
        class="btn__simple navbar__btn--section smooth-scrolling navbar__btn--section--mobile"
        >Hotel</a
      >

      <a
      href="#kontakt"
      class="btn__simple navbar__btn--section smooth-scrolling navbar__btn--section--mobile"
      >Kontakt & Infos</a
      >
        </div>
      </div>`;
  }
}

export default new navbarView();
