"use strict";

import "core-js/stable";
import "regenerator-runtime/runtime";
import "fullpage.js";
import smoothscroll from "smoothscroll-polyfill";

import * as model from "./model.js";

import smoothScrollingView from "./views/smoothScrollingView.js";
// import smoothRevealView from "./views/smoothRevealView.js";
import navbarView from "./views/navbarView.js";
import imgView from "./views/imgView.js";
import navbarAnimation from "./views/navbarAnimation.js";
import fullpageView from "./views/fullpageView.js";

// ========= FULL INITIALIZATION ============
//

const init = function () {
  navbarView.render(true);
  navbarView.handleDropdown();
  navbarAnimation.handleNavbarAnimation();
  smoothScrollingView.handleSmoothScrolling();
  imgView.lazyLoadingImg();
  // smoothRevealView.handleSmoothReveal(model.state.pageViewed);
  if (window.screen.width > 600 & window.screen.height > 600) {
    fullpageView.handleFullpageView();
  }
};

// force implementation
window.__forceSmoothScrollPolyfill__ = true;

smoothscroll.polyfill();

// initialization of controller
init();
